import React, { useState, useEffect } from 'react';

const PrivateData = () => {
  const [opacity, setOpacity] = useState(0);
  const [y, setY] = useState(-100);

  useEffect(() => {
    setOpacity(1);
    setTimeout(() => {
      setY(0);
    }, 500);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        opacity,
      }}
    >
      <h1 style={{ transform: `translateY(${y}px)` }}>
        Client data coming soon
      </h1>
    </div>
  );
};

export default PrivateData;
